import { createModel } from '@rematch/core'
import { RootModel } from '../../models'
import * as service from './service'
import notification from '@components/notification'
import { capitalize } from '@helpers/index'
import i18n from '@/i18n'

interface TranslationState {
  loading: boolean
  error: string | null
  translations: {
    [key: string]: {
      translatedText: string
      sourceLanguage: string
      translations: {
        [key: string]: string
      }
    }
  }
}

export const translation = createModel<RootModel>()({
  state: {
    loading: false,
    error: null,
    translations: {}
  } as TranslationState,

  reducers: {
    setLoading(state, loading: boolean) {
      return { ...state, loading }
    },
    setError(state, error: string) {
      notification('error', capitalize(error))
      return { ...state, error, loading: false }
    },
    setTranslation(
      state,
      {
        key,
        translation
      }: {
        key: string
        translation: { translatedText: string; sourceLanguage: string; translations: { [key: string]: string } }
      }
    ) {
      return {
        ...state,
        translations: {
          ...state.translations,
          [key]: translation
        },
        loading: false
      }
    }
  },

  effects: (dispatch) => ({
    async translateContent(payload: { feature: string; field: string; document_id: string; target_language: string }) {
      try {
        dispatch.translation.setLoading(true)
        const response = await service.translateContent(payload)

        if (response && response.translations && response.translations[payload.target_language]) {
          const translationKey = `${payload.feature}_${payload.field}_${payload.document_id}`
          dispatch.translation.setTranslation({
            key: translationKey,
            translation: {
              translatedText: response.translations[payload.target_language],
              sourceLanguage: response.sourceLanguage,
              translations: response.translations
            }
          })
          return response.translations[payload.target_language]
        } else {
          dispatch.translation.setLoading(false)
        }
      } catch (error: any) {
        const errorMessage = error && error.message ? error.message : i18n.t('common.please-try-again-later')
        dispatch.translation.setError(errorMessage)
      }
    }
  })
})
