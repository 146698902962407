import { api, catchHandler } from '@helpers/axios'
import config from '@config/index'

interface TranslatePayload {
  feature: string
  field: string
  document_id: string
  target_language: string
}

interface TranslateResponse {
  sourceText: string
  sourceLanguage: string
  translations: {
    [key: string]: string
  }
  reliable: number
}

export const translateContent = async (payload: TranslatePayload): Promise<TranslateResponse> => {
  return api()
    .post(config.routes.translate, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
